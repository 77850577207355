import Vue from 'vue';
import vClickOutside from 'v-click-outside';
import $events from 'wee-events';
import { $setVar } from 'wee-store';
import { $setRef } from 'core/dom';
import { noOutline } from './utility';
import 'es6-promise/auto';
import '../styles/global.scss';
import 'flickity/css/flickity.css';
import 'flickity-fullscreen/fullscreen.css';

Vue.filter('titlecase', (str) => {
    if (!str) return '';

    const string = str.toString();
    return string.toLowerCase().split(' ').map(word => word.replace(word[0], word[0].toUpperCase())).join(' ');
});

Vue.use(vClickOutside);

// Import all component scss files
require.context('../components', true, /\.scss$/);

$setRef();
$setVar();

$events.on({
    a: {
        mousedown(e, el) {
            noOutline(el);
        },
    },
    button: {
        mousedown(e, el) {
            noOutline(el);
        },
    },
    '.js-no-outline': {
        mousedown(e, el) {
            noOutline(el);
        },
    },
}, {
    delegate: 'body',
});
