<template>
    <div>
        <hero-slider
            v-if="featuredAuctions.length"
            :slides="featuredAuctions"
        />

        <auction-filters
            ref="auctionFilters"
            v-model="filters"
            :disabled="loading"
        />

        <section class="header-texture auctions__main halftone">
            <div class="container auctions__inner">
                <div
                    v-if="initalLoad && loading"
                    class="auctions__loader-wrap"
                >
                    <loader class="auctions__loader" />
                </div>
                <div v-if="auctions.length">
                    <ul
                        class="auctions__list"
                        :class="{ '-is-loading': loading }"
                    >
                        <li
                            v-for="auction in auctions"
                            :key="auction.id"
                            class="auctions__list-item"
                            :data-id="auction.id"
                            :class="{
                                '-upcoming': auction.status == 'upcoming',
                                '-available': auction.status == 'available',
                                '-sold': auction.status == 'sold',
                                '-pending': auction.status == 'pending'
                            }"
                        >
                            <div class="auction-list__item">
                                {{ loading ? 'Loading' : null }}
                                <a
                                    :href="auction.url"
                                    class="auction-list__image-wrapper"
                                    @click="handleAuctionListingsAnalytics(
                                        auction,
                                        'listing search',
                                        'card'
                                    )"
                                >
                                    <img
                                        :src="auction.imageCard"
                                        sizes="(min-width: 767px) 112px, 251px"
                                        class="auction-list__image"
                                        :alt="auction.altText
                                            ? auction.altText
                                            : auction.image.title
                                        "
                                    >
                                </a>
                                <div class="auction-list__auction">
                                    <p
                                        class="auction-list__tract"
                                    >
                                        {{ auction.tract ? auction.tract : '' }}
                                    </p>
                                    <a
                                        :href="auction.url"
                                        class="auction-list__auction-link"
                                        @click="handleAuctionListingsAnalytics(
                                            auction,
                                            'listing search',
                                            'card'
                                        )"
                                    >
                                        {{ parseFloat(auction.acreage) }}
                                        Acres / {{ auction.stateCounty }}
                                    </a>
                                    <time
                                        :datetime="auction.datetime"
                                        class="auction-list__date"
                                    >
                                        {{ auction.date ? auction.date : '' }}
                                        {{ auction.time ? ` at ${auction.time}` : '' }}
                                    </time>
                                </div>
                            </div>
                        </li>
                    </ul>

                    <pagination
                        :pagination="meta"
                        :value="filters.page"
                        @input="setPage"
                    />
                </div>
                <div
                    v-else-if="!auctions.length && !loading"
                    class="auctions__error-wrap"
                >
                    <p
                        class="auctions__error"
                    >
                        {{ errorMessage }}
                    </p>
                    <button
                        class="button auctions__reset-filters-button"
                        @click="resetFilters"
                    >
                        Reset Filters
                    </button>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { $unserialize, $serialize } from 'core/types';
import $fetch from 'wee-fetch';
import HeroSlider from './HeroSlider.vue';
import AuctionFilters from './AuctionFilters.vue';
import Loader from './Loader.vue';
import Pagination from './Pagination.vue';
import handleAuctionListingsAnalytics from '../analytics/pages/auctions';

const defaultFilters = () => ({
    page: 1,
    state: 'all',
    county: 'all',
    acreageMin: null,
    acreageMax: null,
    statuses: [
        'upcoming',
        'available',
        'pending',
    ],
});

export default {
    name: 'Auctions',
    components: {
        AuctionFilters,
        HeroSlider,
        Loader,
        Pagination,
    },
    props: {
        featuredAuctions: {
            type: Array,
            required: true,
        },
    },
    data: () => ({
        filters: defaultFilters(),
        auctions: [],
        meta: {},
        loading: false,
        errorMessage: 'No auctions found.',
        initalLoad: true,
    }),
    watch: {
        filters: {
            handler() {
                this.fetchAuctions();
            },
            deep: true,
        },
    },
    created() {
        const query = $unserialize(window.location.search.substring(1));

        if (Object.keys(query).length) {
            this.filters = {
                ...this.filters,
                ...query,
            };
        }
    },
    methods: {
        handleAuctionListingsAnalytics,
        resetFilters() {
            this.filters = defaultFilters();
        },
        setPage(page) {
            this.filters.page = page;

            const urlParams = Object.entries(this.filters)
                .filter(([, value]) => value !== null && value !== '')
                .reduce((acc, [key, value]) => {
                    acc[key] = value;

                    return acc;
                }, {});

            window.history.pushState(null, null, window.location.pathname + '?' + $serialize(urlParams));

            this.$nextTick(() => {
                const top = this.$refs.auctionFilters.$el.offsetTop - 80;

                window.scroll({ top, left: 0, behavior: 'smooth' });
            });
        },
        async fetchAuctions() {
            this.loading = true;

            try {
                const { data } = await $fetch.get('/api/auctions', {
                    params: this.filters,
                });

                this.auctions = data.data;
                this.meta = data.meta;
            } catch (e) {
                /* eslint-disable no-console */
                console.error(e); // TODO
            } finally {
                this.loading = false;

                if (this.initalLoad) {
                    this.initalLoad = false;
                }
            }
        },
    },
};
</script>
