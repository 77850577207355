<template>
    <base-pagination
        :value="pagination.page"
        :records="pagination.total"
        :options="paginationOptions"
        :per-page="pagination.perPage"
        @input="$emit('input', $event)"
    />
</template>

<script>
import $screen from 'wee-screen';
import BasePagination from 'vue-pagination-2';

export default {
    name: 'Pagination',
    components: {
        BasePagination,
    },
    props: {
        pagination: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        paginationOptions: {
            chunk: 5,
            texts: {
                count: 'Showing {from} to {to} of {count} auctions|{count} auctions|One auction',
            },
        },
    }),
    created() {
        $screen.map([
            {
                max: 3,
                callback: () => {
                    this.paginationOptions.chunk = 4;
                },
            },
            {
                min: 4,
                callback: () => {
                    this.paginationOptions.chunk = 10;
                },
            },
        ]);
    },
};
</script>

<style lang="scss">
.VuePagination {
    display: flex;
    justify-content: center;

    .page-item.disabled {
        .page-link {
            background: fade-out($gold, .2);
            // pointer-events: none;
            cursor: not-allowed;
            opacity: .9;
        }
    }

    .page-link {
        display: block;
        padding: 1rem 1.5rem;
        font-size: 1.6rem;
        background: $gold;
        color: $black;
        user-select: none;
        cursor: pointer;

        &:hover {
            background: darken($gold, 10%);
        }

        &:active {
            background: darken($gold, 20%);
        }

        &:focus {
            outline: none;
        }
    }

    &__pagination {
        display: inline-flex;
        margin-bottom: 1rem;
    }

    &__pagination-item {
        .active {
            transform: scale(1.2);
            box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.3)
        }
    }

    &__count {
        text-align: center;
    }
}
</style>
