import pushData from '../../../scripts/datalayer';

// Handle pushing data from auctions listing cards/pins, auctions/calendar filter interactions
export default function handleAuctionListingsAnalytics(auction, pageType, listingClickType) {
    pushData('listing_click', {
        listing_click_type: listingClickType, // 'map' or 'card'
        page_type: pageType,
        listing_click: 1,
        listing_state: auction.stateSlug, // 'alabama' OR 'arkansas'
        listing_county: auction.countySlug, // 'talladega' OR 'van buren'
        listing_zip: auction.zip, // '35160'
        listing_acreage: auction.acreage, // '200'
        listing_auction_type: auction.auctionType.label, // 'reserve' OR 'absolute'
        listing_auction_date: auction.simpleDate, // '2021/12/06'
    });
}
