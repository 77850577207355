<template>
    <div
        ref="slider"
        class="hero-slider"
    >
        <div
            class="glide"
            role="region"
            aria-label="Image Slider"
            aria-live="polite"
        >
            <div
                class="glide__track"
                data-glide-el="track"
            >
                <ol class="glide__slides">
                    <li
                        v-for="(slide, index) in slides"
                        :key="index"
                        class="glide__slide"
                    >
                        <div class="hero-slider__inner">
                            <img
                                :src="slide.image"
                                class="hero-slider__image"
                            >
                            <div class="hero-slider__slide-container">
                                <div class="hero-slider__info js-slide-info">
                                    <ul class="hero-slider__info-list">
                                        <template v-if="slide.type === 'auctionSlide'">
                                            <li class="hero-slider__info-list-title">
                                                Featured Auction
                                            </li>
                                            <li>
                                                {{ slide.auctionAcreage }} acres
                                                / {{ slide.county }}, {{ slide.state }}
                                            </li>
                                            <li>
                                                {{ slide.auctionDateAndTime }}
                                            </li>
                                            <li>
                                                <a
                                                    :href="slide.url"
                                                    class="button -orange hero-slider__info-list-button"
                                                    tabindex="-1"
                                                >
                                                    {{ slide.urlText }}
                                                </a>
                                            </li>
                                        </template>
                                        <template v-else-if="slide.type === 'imageSlide'">
                                            <li class="hero-slider__info-list-title">
                                                {{ slide.title }}
                                            </li>
                                            <li class="hero-slider__info-list-subtitle">
                                                {{ slide.subtitle }}
                                            </li>
                                            <li>
                                                <a
                                                    :href="slide.url"
                                                    class="button -orange hero-slider__info-list-button"
                                                    tabindex="-1"
                                                    :target="slide.openInNewTab ? '_blank' : '_self'"
                                                >
                                                    {{ slide.urlText }}
                                                </a>
                                            </li>
                                        </template>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </li>
                </ol>
            </div>
            <div class="glide__arrows">
                <button
                    class="glide__arrow glide__arrow--left"
                    aria-label="Previous Slide"
                    role="button"
                    @click="previousSlide"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                    ><path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z" /></svg>
                </button>
                <button
                    class="glide__arrow glide__arrow--right"
                    aria-label="Next Slide"
                    role="button"
                    @click="nextSlide"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                    ><path d="M13.1714 12.0007L8.22168 7.05093L9.63589 5.63672L15.9999 12.0007L9.63589 18.3646L8.22168 16.9504L13.1714 12.0007Z" /></svg>
                </button>
                <button
                    class="glide__arrow glide__arrow--small glide__arrow--bottom-right"
                    aria-label="Toggle Slider"
                    role="button"
                    :aria-pressed="sliderIsPlaying ? 'false' : 'true'"
                    @click="toggleSlider"
                >
                    <svg
                        v-if="sliderIsPlaying"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                    ><path d="M6 5H8V19H6V5ZM16 5H18V19H16V5Z" /></svg>
                    <svg
                        v-else
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                    ><path d="M19.376 12.4158L8.77735 19.4816C8.54759 19.6348 8.23715 19.5727 8.08397 19.3429C8.02922 19.2608 8 19.1643 8 19.0656V4.93408C8 4.65794 8.22386 4.43408 8.5 4.43408C8.59871 4.43408 8.69522 4.4633 8.77735 4.51806L19.376 11.5838C19.6057 11.737 19.6678 12.0474 19.5146 12.2772C19.478 12.3321 19.4309 12.3792 19.376 12.4158Z" /></svg>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Glide, { Autoplay, Controls, Keyboard } from '@glidejs/glide/dist/glide.modular.esm';
import '@glidejs/glide/dist/css/glide.core.min.css';
import '@glidejs/glide/dist/css/glide.theme.min.css';

export default {
    name: 'HeroSlider',
    props: {
        slides: {
            type: Array,
            required: true,
        },
    },
    data: () => ({
        sliderIsPlaying: true,
    }),
    mounted() {
        this.glide = new Glide(this.$refs.slider, {
            type: 'carousel',
            autoplay: 5000,
            perView: 1,
            gap: 0,
            animationDuration: 300,
            animationTimingFunc: 'ease-in-out',
        }).mount({ Autoplay, Controls, Keyboard });
    },
    methods: {
        nextSlide() {
            this.glide.go('>');
        },
        previousSlide() {
            this.glide.go('<');
        },
        toggleSlider() {
            if (this.sliderIsPlaying) {
                this.pauseSlider();
            } else {
                this.playSlider();
            }
        },
        pauseSlider() {
            this.glide.pause();

            this.sliderIsPlaying = false;
        },
        playSlider() {
            this.glide.play();

            this.sliderIsPlaying = true;
        },
    },
};
</script>

<style lang="scss">
.hero-slider {
    background-color: $brown;
    height: 40rem;

    &__image {
        vertical-align: top;
        object-fit: cover;
        height: 22rem;
        width: 100%;
    }

    &__info {
        background: $brown;
        padding: 1.5rem;
        color: white;
        height: 100%;
    }

    &__info-list {
        list-style: none;
        padding: 0;
        margin: 0;
        color: $white;
    }

    &__info-list-title {
        font-family: $headingFont;
        font-size: 2.5rem;
        font-weight: $boldFontWeight;
        color: $gold;
    }

    &__info-list-button {
        display: block;
        margin-top: 2rem;
    }
}

.glide {
    &__slide {
        width: 100%;
        height: 40rem;
        position: relative;
    }

    &__arrow {
        &:focus-visible {
            outline: 2px solid $gold;
        }

        svg {
            width: 3rem;
            fill: $white;
        }

        top: 27%;
        padding: .5rem;
        border: unset;
        background-color: rgba(0, 0, 0, .35);
    }

    &__arrow--small {
        svg {
            width: 2rem;
        }
    }

    &__arrow--right {
        right: 1em;
    }

    &__arrow--left {
        left: 1em;
    }

    &__arrow--bottom-right {
        bottom: 13.5em;
        right: 1em;
        top: unset;
    }
}

@tablet {
    .hero-slider {
        height: 45rem;

        &__image {
            height: 28rem;
        }

        &__info {
            transition: all 1s ease-in-out;

            &.-right {
                transform: translateX(3rem);
            }

            &.-left {
                transform: translateX(-3rem);
            }

            &.-active {
                transform: translateX(0);
            }
        }
    }

    .glide {
        &__arrow {
            top: 35%;
        }

        &__arrow--bottom-right {
            bottom: 9.5em;
            top: unset;
        }
    }
}

@desktop {
    .hero-slider {
        height: 40rem;

        &__slide-container {
            position: relative;
            max-width: $maxWidth;
        }

        &__image {
            height: 40rem;
        }

        &__info {
            background-color: rgba(63, 41, 27, .7);
            backdrop-filter: blur(15px);
            position: absolute;
            height: initial;
            left: 10rem;
            bottom: 4rem;
            min-width: 28rem;
            max-width: 35rem;
            box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
        }

        &__inner {
            position: relative;
        }
    }

    .glide {
        &__arrow {
            svg {
                width: 4rem;
            }

            top: 50%;
            padding: .5rem;
        }

        &__arrow--small {
            svg {
                width: 2.5rem;
            }
        }

        &__arrow--right {
            right: 2em;
        }

        &__arrow-left {
            left: 2em;
        }

        &__arrow--bottom-right {
            bottom: 0;
            right: 2em;
            top: unset;
        }
    }
}
</style>
