var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.featuredAuctions.length)?_c('hero-slider',{attrs:{"slides":_vm.featuredAuctions}}):_vm._e(),_vm._v(" "),_c('auction-filters',{ref:"auctionFilters",attrs:{"disabled":_vm.loading},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}}),_vm._v(" "),_c('section',{staticClass:"header-texture auctions__main halftone"},[_c('div',{staticClass:"container auctions__inner"},[(_vm.initalLoad && _vm.loading)?_c('div',{staticClass:"auctions__loader-wrap"},[_c('loader',{staticClass:"auctions__loader"})],1):_vm._e(),_vm._v(" "),(_vm.auctions.length)?_c('div',[_c('ul',{staticClass:"auctions__list",class:{ '-is-loading': _vm.loading }},_vm._l((_vm.auctions),function(auction){return _c('li',{key:auction.id,staticClass:"auctions__list-item",class:{
                            '-upcoming': auction.status == 'upcoming',
                            '-available': auction.status == 'available',
                            '-sold': auction.status == 'sold',
                            '-pending': auction.status == 'pending'
                        },attrs:{"data-id":auction.id}},[_c('div',{staticClass:"auction-list__item"},[_vm._v("\n                            "+_vm._s(_vm.loading ? 'Loading' : null)+"\n                            "),_c('a',{staticClass:"auction-list__image-wrapper",attrs:{"href":auction.url},on:{"click":function($event){return _vm.handleAuctionListingsAnalytics(
                                    auction,
                                    'listing search',
                                    'card'
                                )}}},[_c('img',{staticClass:"auction-list__image",attrs:{"src":auction.imageCard,"sizes":"(min-width: 767px) 112px, 251px","alt":auction.altText
                                        ? auction.altText
                                        : auction.image.title}})]),_vm._v(" "),_c('div',{staticClass:"auction-list__auction"},[_c('p',{staticClass:"auction-list__tract"},[_vm._v("\n                                    "+_vm._s(auction.tract ? auction.tract : '')+"\n                                ")]),_vm._v(" "),_c('a',{staticClass:"auction-list__auction-link",attrs:{"href":auction.url},on:{"click":function($event){return _vm.handleAuctionListingsAnalytics(
                                        auction,
                                        'listing search',
                                        'card'
                                    )}}},[_vm._v("\n                                    "+_vm._s(parseFloat(auction.acreage))+"\n                                    Acres / "+_vm._s(auction.stateCounty)+"\n                                ")]),_vm._v(" "),_c('time',{staticClass:"auction-list__date",attrs:{"datetime":auction.datetime}},[_vm._v("\n                                    "+_vm._s(auction.date ? auction.date : '')+"\n                                    "+_vm._s(auction.time ? ` at ${auction.time}` : '')+"\n                                ")])])])])}),0),_vm._v(" "),_c('pagination',{attrs:{"pagination":_vm.meta,"value":_vm.filters.page},on:{"input":_vm.setPage}})],1):(!_vm.auctions.length && !_vm.loading)?_c('div',{staticClass:"auctions__error-wrap"},[_c('p',{staticClass:"auctions__error"},[_vm._v("\n                    "+_vm._s(_vm.errorMessage)+"\n                ")]),_vm._v(" "),_c('button',{staticClass:"button auctions__reset-filters-button",on:{"click":_vm.resetFilters}},[_vm._v("\n                    Reset Filters\n                ")])]):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }