import $router from 'wee-routes';
import Vue from 'vue';
import './bootstrap';

/**
 * Dynamically load a component
 * @param {String} component
 */
const load = component => import(/* webpackChunkName: "[request]" */ '../components/' + component)
    .then(m => m.default || m);

// Standard JS Files
const header = () => load('header');
const cardSwiper = () => load('card-swiper');
const calendar = () => load('calendar');
const faq = () => load('faq');
const auction = () => load('auction');
// const auctionSwiper = () => load('auction-list');
const auctionsListings = () => load('auctions');
// const alertCta = () => load('alert-cta');
const stayUpdatedPage = () => load('stay-updated-page');
const commonAnalytics = () => load('analytics/common');
const equipmentAuctions = () => load('equipment-auctions');

// Common script array
const common = [header, commonAnalytics];

// Analytics JS files
// Dynamically load an analytics component
const loadAnalytics = analytic => import(/* webpackChunkName: "[request]" */ '../components/analytics/pages/' + analytic)
    .then(m => m.default || m);

const auctionDetailAnalytics = () => loadAnalytics('auctionDetail');
const contactUsAnalytics = () => loadAnalytics('contactUs');
const thankYouAnalytics = () => loadAnalytics('thankYouPage');
const newsletterThankYouAnalytics = () => loadAnalytics('thankYouNewsletterPage');

Vue.component('AuctionsIndex', require('../components/auctions/index.vue').default);

// Site Router
$router.map([
    {
        path: '/calendar',
        handler: [...common, calendar],
        meta: {
            pageType: 'calendar',
        },
    },
    {
        path: '/',
        handler: [...common, auctionsListings],
        meta: {
            pageType: 'listing search',
        },
    },
    {
        path: '/auctions/*',
        handler: [...common, auction, faq, cardSwiper, auctionDetailAnalytics],
        meta: {
            pageType: 'listing detail',
        },
    },
    {
        path: '/newsletter',
        handler: [...common, stayUpdatedPage],
        meta: {
            pageType: 'newsletter',
        },
    },
    {
        path: '/sell',
        handler: [...common],
        meta: {
            pageType: 'sell property',
        },
    },
    {
        path: '/contact-us',
        handler: [...common, contactUsAnalytics],
        meta: {
            pageType: 'contact us',
        },
    },
    {
        path: '/how-it-works',
        handler: [...common],
        meta: {
            pageType: 'how it works',
        },
    },
    {
        path: '/why-us',
        handler: [...common],
        meta: {
            pageType: 'why us',
        },
    },
    {
        path: '/terms',
        handler: [...common],
        meta: {
            pageType: 'about',
        },
    },
    {
        path: '/privacy-policy',
        handler: [...common],
        meta: {
            pageType: 'about',
        },
    },
    {
        path: '/thank-you-property',
        handler: [...common, thankYouAnalytics],
        meta: {
            pageType: 'thank you',
        },
    },
    {
        path: '/thank-you-newsletter',
        handler: [...common, newsletterThankYouAnalytics],
        meta: {
            pageType: 'thank you',
        },
    },
    {
        path: '/equipment-auctions',
        handler: [...common, equipmentAuctions],
        meta: {
            pageType: 'equipment auctions',
        },
    },
]).notFound({
    handler: common,
    meta: {
        pageType: 'page',
    },
}).run();
