<template>
    <div
        class="auction-filters"
        :class="{
            '-is-disabled': disabled,
        }"
    >
        <div class="container">
            <div
                class="auction-filters__inner"
                :class="{
                    '-is-active': filtersActive,
                }"
            >
                <button
                    class="button auction-filters__toggle"
                    @click="filtersActive = !filtersActive"
                >
                    Filter Auctions
                </button>
                <div class="auction-filters__select">
                    <select
                        v-model="filters.state"
                        class=""
                        name="state"
                    >
                        <option value="all">
                            Select a state
                        </option>
                        <option
                            v-for="state in states"
                            :key="state.id"
                            :value="state.slug"
                        >
                            {{ state.title }} ({{ state.auctionCount }})
                        </option>
                    </select>
                </div>

                <div class="auction-filters__select">
                    <select
                        v-model="filters.county"
                        name="county"
                        :disabled="!counties?.length"
                    >
                        <template v-if="counties?.length">
                            <option value="all">
                                All counties
                            </option>
                            <option
                                v-for="county in counties"
                                :key="county.id"
                                :value="county.slug"
                                :selected="filters.county === county.slug"
                            >
                                {{ county.title }} ({{ county.auctionCount }})
                            </option>
                        </template>
                        <template v-else>
                            <option value="all">
                                Select a state first
                            </option>
                        </template>
                    </select>
                </div>

                <fieldset
                    ref="statusDropdown"
                    v-click-outside="closeFilter('status')"
                    class="auction-filters__fieldset"
                    @keydown="handleSelectKeydown($event, 'status')"
                >
                    <legend class="auction-filters__fieldset-legend">
                        Status
                    </legend>
                    <div
                        class="auction-filters__fieldset-desktop-toggle"
                        :class="{
                            '-is-open': statusOpen,
                        }"
                        tabindex="0"
                        @click="toggleFilter('status')"
                    >
                        Status
                    </div>
                    <div
                        class="auction-filters__fieldset-wrap"
                        :class="{
                            '-is-open': statusOpen,
                        }"
                    >
                        <label
                            for="upcoming"
                            class="auction-filters__fieldset-checkbox-label"
                        >
                            <input
                                id="upcoming"
                                v-model="filters.statuses"
                                class="auction-filters__fieldset-checkbox"
                                type="checkbox"
                                value="upcoming"
                            > Upcoming
                        </label>
                        <label
                            for="available"
                            class="auction-filters__fieldset-checkbox-label"
                        >
                            <input
                                id="available"
                                v-model="filters.statuses"
                                class="auction-filters__fieldset-checkbox"
                                type="checkbox"
                                value="available"
                            > Available
                        </label>
                        <label
                            for="pending"
                            class="auction-filters__fieldset-checkbox-label"
                        >
                            <input
                                id="pending"
                                v-model="filters.statuses"
                                class="auction-filters__fieldset-checkbox"
                                type="checkbox"
                                value="pending"
                            > Pending
                        </label>
                        <label
                            for="sold"
                            class="auction-filters__fieldset-checkbox-label"
                        >
                            <input
                                id="sold"
                                v-model="filters.statuses"
                                class="auction-filters__fieldset-checkbox"
                                type="checkbox"
                                value="sold"
                            > Sold
                        </label>
                    </div>
                </fieldset>

                <fieldset
                    ref="acreageDropdown"
                    v-click-outside="closeFilter('acreage')"
                    class="auction-filters__fieldset"
                    @keydown="handleSelectKeydown($event, 'acreage')"
                >
                    <legend class="auction-filters__fieldset-legend">
                        Acreage
                    </legend>
                    <div
                        class="auction-filters__fieldset-desktop-toggle"
                        :class="{
                            '-is-open': acreageOpen,
                        }"
                        tabindex="0"
                        @click="toggleFilter('acreage')"
                    >
                        Acreage
                    </div>
                    <div
                        class="auction-filters__fieldset-wrap"
                        :class="{
                            '-is-open': acreageOpen,
                        }"
                    >
                        <label class="auction-filters__acres-input">
                            Min
                            <input
                                type="number"
                                class="input"
                                placeholder="Min"
                                :value="filters.acreageMin"
                                name="min"
                                min="0"
                                @input="debounceInput('acreageMin', $event.target.value)"
                            >
                        </label>
                        <label class="auction-filters__acres-input">
                            Max
                            <input
                                type="number"
                                class="input"
                                placeholder="Max"
                                :value="filters.acreageMax"
                                name="max"
                                min="0"
                                @input="debounceInput('acreageMax', $event.target.value)"
                            >
                        </label>
                    </div>
                </fieldset>

                <button
                    class="button auction-filters__reset"
                    @click="resetFilters"
                >
                    Reset Filters
                </button>
            </div>
        </div>
    </div>
</template>

<script>
/* global structuredClone */

import { $serialize } from 'core/types';
import debounce from 'lodash.debounce';
import $fetch from 'wee-fetch';
import 'vue-multiselect/dist/vue-multiselect.min.css';

import Tabbable from '../../scripts/tabbable';

export default {
    name: 'AuctionFilters',
    props: {
        value: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            statusOpen: false,
            acreageOpen: false,
            filters: structuredClone(this.value),
            initialFilters: structuredClone(this.value),
            states: [],
            counties: [],
            filtersActive: false,
            page: 1,
        };
    },
    watch: {
        filters: {
            handler() {
                this.$emit('input', this.filters);

                const urlParams = Object.entries(this.filters)
                    .filter(([, value]) => value !== null && value !== '')
                    .reduce((acc, [key, value]) => {
                        acc[key] = value;

                        return acc;
                    }, {});

                window.history.pushState(null, null, window.location.pathname + '?' + $serialize(urlParams));
            },
            deep: true,
        },
        // eslint-disable-next-line no-useless-computed-key, object-shorthand
        ['filters.state'](slug) {
            this.getCountiesForState(slug);
        },
    },
    async created() {
        const { data: { data: states } } = await $fetch.get('/api/auction-states');

        this.states = states;
    },
    methods: {
        handleSelectKeydown(e, filter) {
            if (['Excape', 'Space'].includes(e.code)) {
                this.toggleFilter(filter);
                e.preventDefault();
            }
        },
        resetFilters() {
            this.filters = structuredClone(this.initialFilters);
            this.counties = [];
        },
        closeFilter(filter) {
            return () => {
                this[`${filter}Open`] = false;
            };
        },
        toggleFilter(filter) {
            this[`${filter}Open`] = !this[filter + 'Open'];

            this.$nextTick(() => {
                let tabbable = new Tabbable({
                    el: this.$refs[`${filter}Dropdown`].querySelector('.auction-filters__fieldset-wrap.-is-open'),
                    callback: () => {
                        this[`${filter}Open`] = false;

                        tabbable.destroy();
                        tabbable = null;
                    },
                });
            });
        },
        /* eslint-disable-next-line func-names */
        debounceInput: debounce(function (key, value) {
            this.filters[key] = value;
        }, 500),
        async getCountiesForState(slug) {
            const { data: { data: [counties] } } = await $fetch.get(`/api/auction-states/${slug}/counties`);

            this.counties = counties;

            this.filters = {
                ...this.filters,
                county: 'all',
                page: 1,
            };
        },
    },
};
</script>

<style lang="scss">
.auction-filters {
    background-color: #fff;
    opacity: 1;
    transition: opacity .2s ease-in-out;

    &.-is-disabled {
        opacity: 0.5;
        pointer-events: none;
        position: relative;
        z-index: 100;
    }

    &__inner {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        max-height: 7.5rem;
        overflow: hidden;

        &.-is-active {
            max-height: unset;
        }
    }

    &__toggle,
    &__reset {
        font-size: 2rem;
        width: 100%;
    }

    &__toggle {
        margin-bottom: 2rem;
        background-color: $accent;
    }

    &__reset {
        padding: $inputPaddingVertical $inputPaddingHorizontal;
        font-size: $inputFontSize;
        background-color: $gold;
        color: $black;
        border: 1px solid $black;
    }

    &__fieldset-desktop-toggle {
        display: none;

        &.-is-open {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    &__select {
        position: relative;
        margin-bottom: 2rem;
        cursor: pointer;

        select {
            background-color: #fff;
            color: $brown;
            font-size: $inputFontSize;
            border-radius: $inputRounded;
            border: 1px solid $inputBorderColor;
            padding: $inputPaddingVertical $inputPaddingHorizontal;
            width: 100%;
            -webkit-appearance: none;
            -moz-appearance: none;

            &:focus {
                outline: none;
                border-color: $inputBorderColorFocus;
            }

            &:hover {
                border-color: $inputBorderColorHover;
            }
        }

        &::after {
            @include icon($down-icon);

            color: inherit;
            font-size: 1.2rem;
            font-weight: 800;
            line-height: 1em;
            padding-left: 1.4rem;
            pointer-events: none;
            position: absolute;
            right: 1.5rem;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &__fieldset {
        border: none;
        padding: 1rem;
    }

    &__fieldset-checkbox {
        display: block;
        margin-bottom: 1rem;
        cursor: pointer;

        input {
            margin-right: 0.5rem;
            accent-color: $gold;
        }
    }

    &__fieldset-legend {
        display: block;
        font-size: 1.5rem;
        font-weight: 800;
    }

    &__fieldset-checkbox {
        min-height: 1.6rem;
        min-width: 1.6rem;
        margin: 0 .8rem 0 0;
        accent-color: $gold;
    }

    &__fieldset-checkbox-label {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        cursor: pointer;
    }

    &__acres-input {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;

        input {
            margin-left: 0.5rem;
            width: 100%;
            margin-bottom: 0;
            min-width: 8rem;
        }
    }

    &__acres-wrap {
        display: grid;
        grid-template-columns: 15rem 15rem;
        grid-gap: 1rem;
    }
}

@desktop {
    .auction-filters {
        background-color: $gold;

        &__inner {
            max-height: unset;
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            grid-gap: 2rem;
            overflow: unset;
        }

        &.-is-disabled {}

        &__toggle {
            display: none;
        }

        &__select {
            height: min-content;
            margin-bottom: 0;
        }

        &__fieldset {
            padding: 0;
            position: relative;
        }

        &__fieldset-desktop-toggle {
            background: #fff;
            display: block;
            color: $inputColor;
            font-size: $inputFontSize;
            border-radius: $inputRounded;
            border: 1px solid $inputBorderColor;
            padding: $inputPaddingVertical $inputPaddingHorizontal;
            position: relative;
            margin: 0;
            height: 4rem;
            cursor: default;
            user-select: none;

            &.-is-open {
                border-color: $inputBorderColorFocus;
            }

            &:focus {
                outline: none;
                border-color: $inputBorderColorFocus;
            }

            &::after {
                @include icon($down-icon);

                color: inherit;
                font-size: 1.2rem;
                font-weight: 800;
                line-height: 1em;
                padding-left: 1.4rem;
                pointer-events: none;
                position: absolute;
                right: 1.5rem;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        &__fieldset-legend {
            display: none;
        }

        &__fieldset-checkbox {
            input {}
        }

        &__fieldset-wrap {
            display: none;
            border: 1px solid $inputBorderColorFocus;
            border-top: none;
            padding: $inputPaddingVertical $inputPaddingHorizontal;
            border-bottom-right-radius: $inputRounded;
            border-bottom-left-radius: $inputRounded;
            position: absolute;
            top: 4rem;
            width: 100%;
            background: #fff;
            z-index: 10;

            &.-is-open {
                display: block;
            }
        }

        &__fieldset-checkbox {}

        &__fieldset-checkbox-label {
            &:last-child {
                margin-bottom: 0;
            }
        }

        &__acres-input {
            display: block;

            input {
                margin-left: 0;
                min-width: unset;
            }
        }

        &__acres-wrap {}
    }
}
</style>
