import { tabbable } from 'tabbable';
import $ from 'wee-dom';
import $events from 'wee-events';
import { _body } from 'core/variables';
import { $isFunction } from 'core/types';

/**
 * Generate a unique nine character alpha-numeric id, i.e "xbtyagw9v"
 * @function
 */
const uid = () => Math.random().toString(36).substr(2, 9);

/**
 * We use this class to assist us in trapping tab focus
 * within an element.
 * @param {object} [options] - The options object
 * @param {$|string} [options.el] - The main element
 * @param {array} [prepend] - any elements to prepend to tabbable element list
 * @param {function} [options.callback] - The callback to be executed upon last element blur
 * @class
 */
export default class Tabbable {
    constructor(options = {}) {
        /**
         * The main element we pass to the taggable function to get
         * an array of tabbable elements contained with in.
         * @member {$}
         */
        this.$el = options.el ? $(options.el) : $(_body);

        /**
         * A Wee selection of all the tabbable elements
         * @member {$}
         */
        this.$tabbableEls = $(tabbable(this.$el[0]));

        // Prepend any additional elements to the tabbable selection
        if (options.prepend?.length) {
            this.$tabbableEls = $([...options.prepend, ...this.$tabbableEls.toArray()]);
        }

        // Append any additional elements to the tabbable selection
        if (options.append?.length) {
            this.$tabbableEls = $([...this.$tabbableEls, ...options.append]);
        }

        /**
         * A Wee selection of the first element
         * @member {$}
         */
        this.$firstEl = this.$tabbableEls.first();

        /**
         * The last element within the tabbable Wee selection
         * @member {$}
         */
        this.$lastEl = this.$tabbableEls.last();

        /**
         * A unique ID we will use for event namespaces for easier
         * internal reference and event destruction
         * @member {string}
         */
        this.uid = uid();

        /**
         * The function that will be executed once the last element
         * in the tabbable elements selection is blurred
         * @member {function}
         */
        this.callback = options.callback;

        /**
         * Whether the user is using shift + tab to back shift in
         * the document
         * @member {boolean}
         */
        this.backShift = false;

        /**
         * They current key
         * @member {boolean}
         */
        this.key = null;

        // Bind a keydown event to the body element to check if the user
        // is using shift + tab, set a state variable accordingly
        $events.on(_body, `keydown.${this.uid}`, (e) => {
            this.backShift = (e.key === 'Tab' && e.shiftKey);
            this.key = e.key;
        });

        $events.on(this.$tabbableEls, `blur.${this.uid}`, (e, el) => {
            if (
                ! this.backShift
                && $(el).is(this.$lastEl)
                && this.key === 'Tab'
                && $isFunction(this.callback)
            ) {
                this.callback(el, this.$tabbableEls);
            }
        });
    }

    first() {
        return this.$firstEl[0];
    }

    /**
     * Unbind bound events
     */
    destroy() {
        $events.off(null, `.${this.uid}`);
    }

    /**
     * Returns an array of tabbable elements
     * @param {string|$} el
     * @param {boolean} [wee=false] - Return as a wee selection
     * @returns {array|object}
     * @static
     */
    static get(el, wee = false) {
        return wee ? $(tabbable(el)) : tabbable(el);
    }
}
