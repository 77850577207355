import $ from 'wee-dom';
import { _doc } from 'core/variables';
import $events from 'wee-events';

// Refactor to accept object of params
/**
 * Toggles classname
 * @param {string} element main element to toggle
 * @param {string} secondaryElement option secondary element to toggle
 * @param {string} ariaElement optional aria attribute
 * @param {string} className
 * @param {num} ariaDirection optional, 1 is default, any other number will flip aria direction
 */
export function toggle(element, secondaryElement, ariaElement, className, ariaDirection = 1) {
    const toggled = $(element).hasClass(className);
    const classMethod = toggled ? 'removeClass' : 'addClass';
    const ariaBool = toggled ? (ariaDirection === 1) : (ariaDirection !== 1);
    const $element = $(element);

    $element[classMethod](className);
    if (ariaElement.length) {
        $element.attr(ariaElement, ariaBool);
    }

    if (secondaryElement.length) {
        $(secondaryElement)[classMethod](className);
    }
}

/**
 * Keeps focus with a specific area
 * keyCode 9 is the tab key
 *
 * @param {array} items
 * @param {*} event
 */
export function setFocus(items, event) {
    if (event.keyCode !== 9) {
        return;
    }

    const $activeEl = $(_doc.activeElement);

    if (event.shiftKey) {
        if ($activeEl.is(items.first())) {
            items.last()[0].focus();
            event.preventDefault();
        }

        return;
    }

    if ($activeEl.is(items.last())) {
        items.first()[0].focus();
        event.preventDefault();
    }
}


export function noOutline(element) {
    const $el = $(element);

    if ($el.css('outline-style') === 'none') {
        const outlineClass = '-no-outline';

        $el.addClass(outlineClass);

        $events.on($el, 'blur', () => {
            $el.removeClass(outlineClass);
        }, {
            once: true,
        });
    }
}
